import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Container, Form, InputGroup, Row, Spinner} from 'react-bootstrap';
import {Send} from 'react-bootstrap-icons';
import './ChatInterface.css';
import {fetchAuthSession} from 'aws-amplify/auth';

const gatewayUrl = 'https://lhpflbm404.execute-api.eu-west-1.amazonaws.com/prod/';

const defaultMessage = [
    {
        "role": "user",
        "content": [{
            "text": "You are a clever and friendly assistant that likes to help your friend. You always provide " +
                "answers or suggestions to the user's remarks in a correct way with a dash of humor. Your " +
                "name is Quippy. In general you try to answer in two or three sentences, unless the topic " +
                "is more complex."
        }]
    },
    {
        "role": "assistant",
        "content": [{
            "text": "My name is Quippy, and I am ready to have a good and fun conversation with you. How can I help."
        }]
    }
]


const ChatInterface = () => {
    const [inputValue, setInputValue] = useState('');
    const [chatMessages, setChatMessages] = useState(() => {
        const savedMessages = localStorage.getItem('chatMessages');
        return savedMessages ? JSON.parse(savedMessages) : defaultMessage;
    });
    const [foundationModels, setFoundationModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('amazon.titan-text-express-v1');

    const [loading, setLoading] = useState(false);

    const chatContainerRef = useRef(null);

    useEffect(() => {
        localStorage.setItem('chatMessages', JSON.stringify(chatMessages));
        scrollToBottom();
    }, [chatMessages]);

    useEffect(() => {
        const savedModel = localStorage.getItem('selectedModel');
        if (savedModel) {
            setSelectedModel(savedModel);
        }
        callFoundationModelsApi();
    }, []);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };


    const callApi = async (updatedMessages, model) => {
        const apiUrl = gatewayUrl + 'hello';

        try {
            setLoading(true);
            const {idToken} = (await fetchAuthSession()).tokens ?? {};

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': idToken?.toString() ?? '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({messages: updatedMessages, model: model}),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('API response:', data);
            setChatMessages([...updatedMessages, {role: 'assistant', content: [{text: data.message}]}]);
        } catch (error) {
            console.error('Error calling API:', error);
            setChatMessages([...updatedMessages, {
                role: 'assistant',
                content: [{text: `Error calling API: ${error.message}`}]
            }]);
        } finally {
            setLoading(false);
        }
    };

    const callFoundationModelsApi = async () => {
        const apiUrl = gatewayUrl + 'foundation-models';
        try {
            setLoading(true);
            const {idToken} = (await fetchAuthSession()).tokens ?? {};

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': idToken?.toString() ?? '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({'region': 'eu-central-1', 'outputModality': 'TEXT'})
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('API response:', data);
            setFoundationModels(data.models || ['amazon.titan-text-express-v1'])
        } catch (error) {
            console.error('Error calling API:', error);
            setFoundationModels(['amazon.titan-text-express-v1'])
        } finally {
            setLoading(false);
        }
    }

    // Add handleModelChange function
    const handleModelChange = (event) => {
        const model = event.target.value;
        setSelectedModel(model);
        localStorage.setItem('selectedModel', model);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleButtonClick = (event) => {
        event.preventDefault();
        if (inputValue.trim() !== '') {
            setChatMessages(prevMessages => {
                const updatedMessages = [...prevMessages, {role: 'user', content: [{text: inputValue}]}];
                callApi(updatedMessages, selectedModel);
                return updatedMessages;
            });
            setInputValue('');
        } else {
            alert('Please type a message before sending');
        }
    };

    const handleClearChat = () => {
        localStorage.removeItem('chatMessages');
        setChatMessages(defaultMessage);
    };


    return (
        <Container className="mt-4">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card>
                        <Card.Header
                            className="bg-primary text-white d-flex justify-content-between align-items-center">
                            <span>Chat with Assistant</span>
                            <Button variant="secondary" onClick={handleClearChat}>New Chat</Button>
                        </Card.Header>
                        <Card.Body ref={chatContainerRef} className="chat-container"
                                   style={{maxHeight: '400px', overflowY: 'auto'}}>
                            {chatMessages.slice(1).map((msg, index) => (
                                <div key={index} className={`chat-message ${msg.role}`}>
                                    <div className="assistant-avatar">
                                        <img
                                            src={msg.role === 'assistant' ? "/images/quippy-headshot.png" : "/images/jettro.jpg"}
                                            alt={msg.role}
                                            className="rounded-circle mr-3"
                                            style={{width: '50px', height: '50px'}}
                                        />
                                    </div>
                                    <div className="message-content">
                                        <div className="bg-light p-3 rounded">
                                            <p className="mb-0">
                                                {msg.content[0].text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Card.Body>
                        <Card.Footer>
                            <Form onSubmit={handleButtonClick}>
                                <InputGroup className="mb-3">
                                    <Form.Select value={selectedModel} onChange={handleModelChange}>
                                        {foundationModels.map(model => (
                                            <option key={model.id} value={model.id}>{model.name}({model.id})</option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Type your message..."
                                        value={inputValue}
                                        onChange={handleInputChange}
                                    />
                                    <InputGroup.Text>
                                        <Button className="btn-primary" type="submit" disabled={loading}>
                                            {loading ? <Spinner animation="border" size="sm"/> : <Send/>}
                                        </Button>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ChatInterface;